:root {
  --default-layout-inner-max-width: 800px;
  --default-layout-outer-max-width: 1000px;
  --default-layout-padding: 2em;
  --default-component-padding: 1em;
  --default-radius: .4em;
  --default-text-font-family: "Jost", serif;
  --default-text-line-height: 1.5;
  --default-text-align: justify;
  --default-text-size: 1em;
  --phone-breakpoint: $ phoneBreakpoint;
  --tablet-breakpoint: $ tabletBreakpoint;
  --default-button-background-color: #176b87;
  --default-button-background-color-hover: #192655;
  --default-button-background-color-active: #04364a;
  --default-button-text-color: #fff;
  --default-button-text-color-hover: #fff;
  --default-button-text-color-active: #fff;
  --default-cockpit-item-padding-vertical: 8px;
  --default-cockpit-item-padding-horizontal: 14px;
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 200;
  src: url("jost-v14-latin-200.53e1c8a6.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 200;
  src: url("jost-v14-latin-200italic.76c216aa.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 300;
  src: url("jost-v14-latin-300.11accbbd.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 300;
  src: url("jost-v14-latin-300italic.b8934790.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  src: url("jost-v14-latin-regular.bd6516c5.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 400;
  src: url("jost-v14-latin-italic.289bfb21.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  src: url("jost-v14-latin-500.d7e419db.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 500;
  src: url("jost-v14-latin-500italic.84726f74.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  src: url("jost-v14-latin-600.9b39a862.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 600;
  src: url("jost-v14-latin-600italic.da78c9cb.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 700;
  src: url("jost-v14-latin-700.dd045bd9.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 700;
  src: url("jost-v14-latin-700italic.571dbcca.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 800;
  src: url("jost-v14-latin-800.c582a0ec.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 800;
  src: url("jost-v14-latin-800italic.819feab9.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: normal;
  font-weight: 900;
  src: url("jost-v14-latin-900.56a4f984.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Jost;
  font-style: italic;
  font-weight: 900;
  src: url("jost-v14-latin-900italic.3021d347.woff2") format("woff2");
}

.oag-map {
  --ol-control-radius: var(--default-radius);
  --ol-control-background-color: #c8c8c880;
  --ol-control-button-text-color: #555;
  --ol-control-button-background-color: #fff;
  --ol-control-button-text-color-hover: #000;
  --ol-control-button-background-color-hover: #eee;
  --ol-control-button-text-color-active: #000;
  --ol-control-button-background-color-active: #ddd;
  --ol-control-attribution-text-color: #555;
  --ol-control-attribution-background-color: #fff;
  --ol-control-gap: .75em;
}

.oag-map .map-element {
  width: 100%;
  height: 100%;
  display: block;
}

.oag-map .map-element.no-ol-touch .ol-touch .ol-control button {
  font-size: inherit;
}

.oag-map .ol-control {
  font-size: 1.2em;
  position: absolute;
}

.oag-map .ol-control.ol-full-screen {
  top: var(--ol-control-gap);
  right: var(--ol-control-gap);
}

.oag-map .ol-control.ol-zoom {
  top: 3em;
  right: var(--ol-control-gap);
}

.oag-map .ol-control.ol-zoom .ol-zoom-in {
  border-radius: var(--ol-control-radius) var(--ol-control-radius) 0 0;
}

.oag-map .ol-control.ol-zoom .ol-zoom-out {
  border-radius: 0 0 var(--ol-control-radius) var(--ol-control-radius);
}

.oag-map .ol-control.ol-attribution {
  text-align: right;
  bottom: var(--ol-control-gap);
  right: var(--ol-control-gap);
  flex-flow: row-reverse;
  align-items: center;
  max-width: calc(100% - 1.3em);
  display: flex;
}

.oag-map .ol-control.ol-attribution a {
  color: var(--ol-control-attribution-text-color);
  text-decoration: none;
}

.oag-map .ol-control.ol-attribution ul {
  color: var(--ol-control-attribution-text-color);
  background: var(--ol-control-attribution-background-color);
  background-color: var(--ol-control-button-background-color);
  border: 1px var(--ol-control-background-color) solid;
  border-radius: var(--ol-control-radius);
  gap: .5em;
  margin: 0 .5em 0 0;
  padding: 1em;
  font-size: 12px;
  transition: all .3s;
  display: flex;
}

.oag-map .ol-control.ol-attribution ul li {
  line-height: 1;
  list-style: none;
  display: inline-block;
}

.oag-map .ol-control.ol-attribution button {
  flex-shrink: 0;
}

.oag-map .ol-control.ol-attribution.ol-collapsed ul {
  display: none;
}

.oag-map .ol-control button {
  color: var(--ol-control-button-text-color);
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  background-color: var(--ol-control-button-background-color);
  border: 1px var(--ol-control-background-color) solid;
  border-radius: var(--ol-control-radius);
  width: 1.75em;
  height: 1.75em;
  margin: 0;
  padding: 0;
  line-height: 0;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.oag-map .ol-control button:hover {
  cursor: pointer;
  color: var(--ol-control-button-text-color-hover);
  background-color: var(--ol-control-button-background-color-hover);
  text-decoration: none;
}

.oag-map .ol-control button:active {
  color: var(--ol-control-button-text-color-active);
  background-color: var(--ol-control-button-background-color-active);
}

.oag-map .ol-touch .ol-control button {
  font-size: 1.75em;
}

.pulsate {
  background: tranparent;
  z-index: 1;
  opacity: 0;
  border: 10px solid red;
  border-radius: 60px;
  width: 50px;
  height: 50px;
  animation: 1s ease-out infinite pulse;
  position: absolute;
  top: -25px;
  left: -25px;
}

@keyframes pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
  }

  25% {
    opacity: .1;
    -webkit-transform: scale(0);
  }

  50% {
    opacity: .3;
    -webkit-transform: scale(.1);
  }

  75% {
    opacity: .5;
    -webkit-transform: scale(.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

* {
  box-sizing: border-box;
}

html, body {
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

htmlhtml, bodyhtml {
  overflow: auto;
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  z-index: 1035;
  background: #000;
  display: none;
  position: fixed;
  inset: 0;
}

.sl-wrapper {
  z-index: 1040;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sl-wrapper * {
  box-sizing: border-box;
}

.sl-wrapper button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-size: 28px;
}

.sl-wrapper button:hover {
  opacity: .7;
}

.sl-wrapper .sl-close {
  z-index: 10060;
  color: #fff;
  width: 44px;
  height: 44px;
  margin-top: -14px;
  margin-right: -14px;
  font-family: Jost, serif;
  font-size: 3rem;
  line-height: 44px;
  display: none;
  position: fixed;
  top: 30px;
  right: 30px;
}

.sl-wrapper .sl-counter {
  z-index: 10060;
  color: #fff;
  font-size: 1rem;
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
}

.sl-wrapper .sl-download {
  text-align: center;
  z-index: 10060;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  display: none;
  position: fixed;
  bottom: 5px;
}

.sl-wrapper .sl-download a {
  color: #fff;
}

.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}

.sl-wrapper .sl-navigation button {
  text-align: center;
  z-index: 10060;
  color: #fff;
  width: 22px;
  height: 44px;
  margin-top: -22px;
  font-family: Jost, serif;
  line-height: 44px;
  display: block;
  position: fixed;
  top: 50%;
}

.sl-wrapper .sl-navigation button.sl-next {
  font-size: 2rem;
  right: 5px;
}

.sl-wrapper .sl-navigation button.sl-prev {
  font-size: 2rem;
  left: 5px;
}

@media (width >= 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    font-size: 3rem;
    right: 10px;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    font-size: 3rem;
    left: 10px;
  }
}

@media (width >= 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    font-size: 3rem;
    right: 20px;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    font-size: 3rem;
    left: 20px;
  }
}

.sl-wrapper.sl-dir-rtl .sl-navigation {
  direction: ltr;
}

.sl-wrapper .sl-image {
  -ms-touch-action: none;
  touch-action: none;
  z-index: 10000;
  position: fixed;
}

.sl-wrapper .sl-image img {
  border: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
}

@media (width >= 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0;
  }
}

@media (width >= 50em) {
  .sl-wrapper .sl-image img {
    border: 0;
  }
}

.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0;
}

@media (width >= 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0;
  }
}

@media (width >= 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0;
  }
}

.sl-wrapper .sl-image .sl-caption {
  color: #fff;
  background: none;
  padding: 10px;
  font-size: 1rem;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-top {
  top: 0;
  bottom: auto;
}

.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}

.sl-spinner {
  opacity: 0;
  z-index: 1007;
  -ms-animation: pulsate 1s ease-out infinite;
  border: 5px solid #333;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  animation: 1s ease-out infinite pulsate;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
}

.sl-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

.sl-transition {
  transition: transform .2s;
}

@keyframes pulsate {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

.sl-image img {
  border-radius: var(--default-radius);
}

.sl-counter, .sl-caption {
  font-family: var(--default-text-font-family);
}

.sl-close:hover, .sl-prev:hover, .sl-next:hover {
  background-color: #0000 !important;
}

p {
  line-height: var(--default-text-line-height);
  text-align: var(--default-text-align);
  font-size: var(--default-text-size);
  margin: .2em 0;
  padding: 0;
  display: inline-block;
}

p strong {
  font-weight: 600;
}

a {
  --bg: #ffffffb3;
  --underline-width: 2px;
  --underline-block-width: 100px;
  --underline-color: #1200914d;
  --underline-color-hover: #120091;
  --underline-transition: .3s;
  color: inherit;
  background-image: linear-gradient(90deg, var(--bg), var(--bg)), linear-gradient(90deg, var(--underline-color), var(--underline-color));
  background-size: var(--underline-block-width) var(--underline-width), 100% var(--underline-width);
  background-repeat: no-repeat;
  background-position-x: calc(var(--underline-block-width) * -1), 0;
  transition: background-position-x var(--underline-transition);
  background-position-y: 100%;
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  background-image: linear-gradient(90deg, var(--bg), var(--bg)), linear-gradient(90deg, var(--underline-color-hover), var(--underline-color-hover));
  background-position-x: calc(100% + var(--underline-block-width)), 0;
}

q {
  quotes: "»" "«";
}

ul {
  padding-inline-start: 30px;
  font-weight: normal;
}

ul li {
  text-align: left;
  padding-left: 10px;
}

ul li::marker {
  content: "–";
}

h1, h2, h3, h4, h5, h6 {
  margin: .4em 0 .2em;
  padding: 0;
  font-weight: 600;
  line-height: 1.35;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.7em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.35em;
}

h5 {
  font-size: 1.15em;
}

h6 {
  font-size: 1em;
}

button, .button {
  --button-size: 1em;
  --button-padding: .9em 1.6em;
  --button-radius: var(--default-radius);
  --button-gap: .7em;
  --button-background-color: var(--default-button-background-color);
  --button-background-color-hover: var(--default-button-background-color-hover);
  --button-background-color-active: var(--default-button-background-color-active);
  --button-text-color: var(--default-button-text-color);
  --button-text-color-hover: var(--default-button-text-color-hover);
  --button-text-color-active: var(--default-button-text-color-active);
  gap: var(--button-gap);
  padding: var(--button-padding);
  font-size: var(--button-size);
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  letter-spacing: .5px;
  border-radius: var(--button-radius);
  border: none;
  flex-direction: row;
  font-weight: 500;
  line-height: 1;
  transition: all .3s;
  display: inline-flex;
}

button svg, .button svg {
  width: var(--button-size);
  height: var(--button-size);
}

button span, .button span {
  line-height: 1;
}

button:hover, .button:hover {
  cursor: pointer;
  background-color: var(--button-background-color-hover);
  color: var(--button-text-color-hover);
}

button:active, .button:active {
  background-color: var(--button-background-color-active);
  color: var(--button-text-color-active);
}

@media screen and (width <= 600px) {
  :root {
    --default-layout-padding: 1.5em;
  }
}
/*# sourceMappingURL=index.93da51ba.css.map */
